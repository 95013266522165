import { FunctionComponent, PropsWithChildren } from 'react'
import { CircularProgress, Stack } from '@mui/material/'
import { useLocation } from 'react-router-dom'
import { useAppStore } from 'store/AppStore'
import Header from './Header/Header'
import Footer from './Footer/Footer'

/**
 * Renders "Public Layout" composition
 */
const PublicLayout: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const location = useLocation()
  const [state] = useAppStore()

  return (
    <>
      <Header displayLogo={location.pathname !== '/'} />

      <Stack
        component="main"
        sx={{
          flexGrow: 1,
          paddingLeft: 1,
          paddingRight: 1,
          paddingTop: 1,
        }}>
        {state.isLoading ? (
          <CircularProgress sx={{ m: 'auto' }} color="secondary" />
        ) : (
          children
        )}
      </Stack>

      <Footer />
    </>
  )
}

export default PublicLayout
