import {
  createContext,
  useReducer,
  useContext,
  FunctionComponent,
  Dispatch,
  ComponentType,
  PropsWithChildren,
} from 'react'
import { AppStoreState } from 'types/store'
import AppReducer from './AppReducer'
import INITIAL_USER_STATE from './states/user'

/**
 * AppState structure and initial values
 */

const INITIAL_APP_STATE: AppStoreState = {
  user: INITIAL_USER_STATE,
  isLoading: false,
}

/**
 * Instance of React Context for global AppStore
 */
type AppContextReturningType = [AppStoreState, Dispatch<any>]
const AppContext = createContext<AppContextReturningType>([
  INITIAL_APP_STATE,
  () => null,
])

/**
 * Main global Store as HOC with React Context API
 *
 * import {AppStoreProvider} from './store'
 * ...
 * <AppStoreProvider>
 *  <App/>
 * </AppStoreProvider>
 */

const AppStoreProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const initialState: AppStoreState = {
    ...INITIAL_APP_STATE,
  }
  const value: AppContextReturningType = useReducer(AppReducer, initialState)

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

/**
 * Hook to use the AppStore in functional components
 *
 * import {useAppStore} from './store'
 * ...
 * const [state, dispatch] = useAppStore();
 */
const useAppStore = (): AppContextReturningType => useContext(AppContext)

/**
 * HOC to inject the ApStore to class component, also works for functional components
 *
 * import {withAppStore} from './store'
 * ...
 * class MyComponent
 * ...
 * export default withAppStore(MyComponent)
 */
interface WithAppStoreProps {
  store: object
}
const withAppStore = (
  Component: ComponentType<WithAppStoreProps>,
): FunctionComponent =>
  function (props) {
    return <Component {...props} store={useAppStore()} />
  }

export { AppStoreProvider as AppStore, AppContext, useAppStore, withAppStore }
