import { AppBar, AppBarProps, Toolbar, Typography, styled } from '@mui/material'
import { SIDEBAR_WIDTH } from 'layout/config'
import { FunctionComponent, ReactNode } from 'react'

interface Props {
  endNode?: ReactNode
  startNode?: ReactNode
  title?: string
  open?: boolean
}

interface CustomAppBarProps extends AppBarProps {
  open?: boolean
}

const CustomAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<CustomAppBarProps>(({ theme, open }) => ({
  color: '#FFFFFF',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: SIDEBAR_WIDTH,
    width: `calc(100% - ${SIDEBAR_WIDTH})`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

/**
 * Renders TopBar composition
 * @component TopBar
 */
const TopBar: FunctionComponent<Props> = ({
  endNode,
  startNode,
  title = '',
  open,
  ...restOfProps
}) => {
  return (
    <CustomAppBar open={open} {...restOfProps}>
      <Toolbar disableGutters sx={{ paddingX: 1 }}>
        {startNode}

        <Typography
          variant="h6"
          sx={{
            marginX: 1,
            flexGrow: 1,
            textAlign: 'center',
            whiteSpace: 'nowrap',
          }}>
          {title}
        </Typography>

        {endNode}
      </Toolbar>
    </CustomAppBar>
  )
}

export default TopBar
