import { User } from 'types/user'

export const IS_SERVER = typeof window === 'undefined'
export const IS_BROWSER =
  typeof window !== 'undefined' && typeof window?.document !== 'undefined'
/* eslint-disable no-restricted-globals */
export const IS_WEBWORKER =
  typeof self === 'object' &&
  self.constructor &&
  self.constructor.name === 'DedicatedWorkerGlobalScope'

/* eslint-enable no-restricted-globals */

export function getCurrentVersion(): string {
  return (
    process.env?.npm_package_version ??
    process.env.REACT_APP_VERSION ??
    'unknown'
  )
}

export function getCurrentEnvironment(): string {
  return process.env?.NODE_ENV ?? 'development'
}

export function isProfileComplete(user: User): boolean {
  return (
    user.firstname !== '' &&
    user.lastname !== '' &&
    user.phone !== '' &&
    user.caterer.company !== ''
  )
}

export function formatAddress(address: any) {
  if (address !== undefined && address.street.length > 0)
    return `${address.street} ${address.zipCode} ${address.city} ${address.country}`
  return ''
}

export function formatEventClothes(clothes: any, type: string) {
  let clothesDesc = ''
  if (clothes.whiteShirt !== undefined && clothes.whiteShirt) {
    if (clothesDesc.length > 0) {
      clothesDesc +=
        type === 'service' ? ' + chemise blanche' : ' + veste blanche'
    } else {
      clothesDesc += type === 'service' ? 'Chemise blanche' : 'Veste blanche'
    }
  }
  if (clothes.blackShirt !== undefined && clothes.blackShirt) {
    if (clothesDesc.length > 0) {
      clothesDesc += type === 'service' ? ' + chemise noire' : ' + veste noire'
    } else {
      clothesDesc += type === 'service' ? 'Chemise noire' : 'Veste noire'
    }
  }
  if (clothes.bowTie !== undefined && clothes.bowTie) {
    if (clothesDesc.length > 0) {
      clothesDesc += ' + noeud papillon'
    } else {
      clothesDesc += 'Noeud papillon'
    }
  }
  if (clothes.suitJacket !== undefined && clothes.suitJacket) {
    if (clothesDesc.length > 0) {
      clothesDesc += ' + veste noire'
    } else {
      clothesDesc += 'Veste noire'
    }
  }
  if (clothes.tie !== undefined && clothes.tie) {
    if (clothesDesc.length > 0) {
      clothesDesc += ' + cravate noire'
    } else {
      clothesDesc += 'Cravate noire'
    }
  }
  if (clothes.vest !== undefined && clothes.vest) {
    if (clothesDesc.length > 0) {
      clothesDesc += ' + gilet noir'
    } else {
      clothesDesc += 'Gilet noir'
    }
  }

  if (clothes.whiteVest !== undefined && clothes.whiteVest) {
    if (clothesDesc.length > 0) {
      clothesDesc += ' + veste blanche'
    } else {
      clothesDesc += 'Veste blanche'
    }
  }

  if (clothes.blackVest !== undefined && clothes.blackVest) {
    if (clothesDesc.length > 0) {
      clothesDesc += ' + veste noir'
    } else {
      clothesDesc += 'Veste noire'
    }
  }

  if (clothesDesc !== '') {
    clothesDesc =
      type === 'service' ? `Salle : ${clothesDesc}` : `Cuisine : ${clothesDesc}`
  }

  return clothesDesc
}

export function formatEventStatus(status: any) {
  if (status === null) return 'En attente'
  if (status) return 'Validé'
  if (!status) return 'Option'
  return 'Non défini'
}
