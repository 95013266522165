import AxiosClient from 'clients/axios'
import { ForgotPassword, Login, Register } from 'types/auth'
import { localStorageGet } from 'utils/localStorage'

const login = async (data: Login) => {
  return AxiosClient.postRequest(`/auth/login`, {
    login: data.login,
    password: data.password,
  })
}

const register = async (data: Register) => {
  return AxiosClient.postRequest(`/auth/register`, { ...data, role: 'caterer' })
}

const verifyToken = async () => {
  return AxiosClient.getRequest(`/auth/token/verify`)
}

const refreshToken = async () => {
  return AxiosClient.postRequest(`/auth/token/refresh`, {
    refreshToken: localStorageGet('refreshToken', false),
  })
}

const forgotPassword = async (data: ForgotPassword) => {
  return AxiosClient.postRequest(`/auth/password/forgot`, data)
}

const resetPassword = async (data: Register, token: string) => {
  return AxiosClient.postRequest(`/auth/password/reset`, { ...data, token })
}

const AuthApi = {
  login,
  register,
  refreshToken,
  verifyToken,
  forgotPassword,
  resetPassword,
}

export default AuthApi
