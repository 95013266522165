import { Route, Routes } from 'react-router-dom'
import routes from './routes'

/**
 * List of routes available only for anonymous users fdsf
 */
const PublicRoutes = () => {
  return (
    <Routes>
      {routes.public.map((route) => (
        <Route key={route.id} path={route.path} element={route.element} />
      ))}
    </Routes>
  )
}

export default PublicRoutes
