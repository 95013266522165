import INITIAL_USER_STATE from 'store/states/user'
import { ActionMap } from 'types/common'
import { UserReducerActions, UserStoreState } from 'types/user'

type UserPayload = {
  [UserReducerActions.Login]: UserStoreState
  [UserReducerActions.Signup]: UserStoreState
  [UserReducerActions.Update]: UserStoreState
  [UserReducerActions.Logout]: UserStoreState
}
export const USER_REDUCER_NAME = 'user'
export type UserActions = ActionMap<UserPayload>[keyof ActionMap<UserPayload>]

export const userReducer = (state: UserStoreState, action: UserActions) => {
  switch (action.type) {
    case UserReducerActions.Login:
      return {
        ...state,
        isAuthenticated: true,
        profileComplete: action.payload.profileComplete,
        isActive: action.payload.isActive,
        isAdmin: action.payload.isAdmin,
        current: action.payload.current,
      }
    case UserReducerActions.Signup:
      return {
        ...state,
        isAuthenticated: true,
        current: action.payload.current,
      }
    case UserReducerActions.Update:
      return {
        ...state,
        profileComplete: action.payload.profileComplete,
        isActive: action.payload.isActive,
        current: action.payload.current,
      }
    case UserReducerActions.Logout:
      return INITIAL_USER_STATE
    default:
      return state
  }
}
