/**
 * Layout configuration
 */

/**
 * SideBar configuration
 */
export const SIDEBAR_MOBILE_ANCHOR = 'right' // 'right';
export const SIDEBAR_DESKTOP_ANCHOR = 'left' // 'right';
export const SIDEBAR_WIDTH = '260px'

/**
 * TopBar configuration
 */
export const TOPBAR_MOBILE_HEIGHT = '56px'
export const TOPBAR_DESKTOP_HEIGHT = '64px'

/**
 * BottomBar configuration
 */
export const BOTTOMBAR_DESKTOP_VISIBLE = false // true;
