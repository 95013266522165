import { Stack, Typography, styled } from '@mui/material'
import AppLogo from 'assets/img/logo-chouquettes.svg'

type Props = {
  displayLogo?: Boolean
}

const LogoBlock = styled('div')(({ theme }) => ({
  width: 150,
  height: 150,
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  marginBottom: 2,
}))

const Logo = styled('img')(({ theme }) => ({
  width: 120,
  marginLeft: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))
const Header = ({ displayLogo = true }: Props) => {
  return (
    <Stack alignItems="center" spacing={3}>
      <Typography component="h1" variant="h4" textAlign="center">
        LES CHOUQUETTES BLANCHES
      </Typography>
      {displayLogo && (
        <LogoBlock>
          <Logo src={AppLogo} alt="logo" />
        </LogoBlock>
      )}
    </Stack>
  )
}

export default Header
