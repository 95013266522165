import { FunctionComponent } from 'react'
import { SvgIcon, SvgIconProps, Tooltip } from '@mui/material'
// SVG assets
// Material Icons
import SettingsIcon from '@mui/icons-material/Settings'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import DayNightIcon from '@mui/icons-material/Brightness4'
import NightIcon from '@mui/icons-material/Brightness3'
import DayIcon from '@mui/icons-material/Brightness5'
import SearchIcon from '@mui/icons-material/Search'
import InfoIcon from '@mui/icons-material/Info'
import HomeIcon from '@mui/icons-material/Home'
import AccountCircle from '@mui/icons-material/AccountCircle'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import PersonIcon from '@mui/icons-material/Person'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined'
import { ReactComponent as AppLogo } from 'assets/img/logo-chouquettes.svg'
import {
  Add,
  AddCircleOutline,
  AllInclusive,
  ArrowBack,
  Build,
  CalendarMonth,
  CallMade,
  CallReceived,
  Check,
  ChevronLeft,
  Dashboard,
  DeleteForever,
  Download,
  ExpandLess,
  ExpandMore,
  Face,
  Groups,
  HighlightOff,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Liquor,
  LocalDining,
  LockReset,
  MenuBook,
  Password,
  People,
  Receipt,
  RoomService,
  Send,
  SentimentSatisfied,
  SentimentVerySatisfied,
  TakeoutDining,
  Tapas,
} from '@mui/icons-material'

/**
 * How to use:
 * 1. Import all required MUI or other SVG icons into this file.
 * 2. Add icons with "unique lowercase names" into ICONS object.
 * 3. Use icons everywhere in the App by their names in <AppIcon name="xxx" /> component
 * Important: properties of ICONS object MUST be lowercase!
 * Note: You can use camelCase or UPPERCASE in the <AppIcon name="someIconByName" /> component
 */
const ICONS: Record<string, React.ComponentType> = {
  default: () => (
    <SvgIcon>
      <AppLogo />
    </SvgIcon>
  ),
  close: CloseIcon,
  menu: MenuIcon,
  settings: SettingsIcon,
  visibilityon: VisibilityIcon,
  visibilityoff: VisibilityOffIcon,
  daynight: DayNightIcon,
  night: NightIcon,
  day: DayIcon,
  search: SearchIcon,
  info: InfoIcon,
  home: HomeIcon,
  account: AccountCircle,
  signup: PersonAddIcon,
  login: PersonIcon,
  logout: ExitToAppIcon,
  notifications: NotificationsIcon,
  chevronleft: ChevronLeft,
  receipt: Receipt,
  dashboard: Dashboard,
  add: Add,
  callmade: CallMade,
  callreceived: CallReceived,
  allinclusive: AllInclusive,
  expandless: ExpandLess,
  expandmore: ExpandMore,
  send: Send,
  addcircle: AddCircleOutline,
  highlightoff: HighlightOff,
  people: People,
  face: Face,
  sentimentsatisfied: SentimentSatisfied,
  sentimentverysatisfied: SentimentVerySatisfied,
  arrowback: ArrowBack,
  deleteforever: DeleteForever,
  check: Check,
  password: Password,
  build: Build,
  groups: Groups,
  lockreset: LockReset,
  calendarmonth: CalendarMonth,
  keyboardarrowleft: KeyboardArrowLeft,
  keyboardarrowright: KeyboardArrowRight,
  menubook: MenuBook,
  roomservice: RoomService,
  takeoutdining: TakeoutDining,
  localdining: LocalDining,
  tapas: Tapas,
  download: Download,
  liquor: Liquor,
}

interface Props {
  name?: string // Icon's name
  icon?: string // Icon's name alternate prop
  title?: string // Icon's name alternate prop
}

/**
 * Renders SVG icon by given Icon name
 * @component AppIcon
 * @param {string} [props.name] - name of the Icon to render
 * @param {string} [props.icon] - name of the Icon to render

 */
const AppIcon: FunctionComponent<Props & SvgIconProps> = ({
  name,
  icon,
  title,
  ...restOfProps
}) => {
  const iconName = (name || icon || 'default').trim().toLowerCase()

  const ComponentToRender = ICONS[iconName] || ICONS.default
  return title ? (
    <Tooltip title={title}>
      <span>
        <ComponentToRender {...restOfProps} />
      </span>
    </Tooltip>
  ) : (
    <ComponentToRender {...restOfProps} />
  )
}

export default AppIcon
