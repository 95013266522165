import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { useAppStore } from 'store/AppStore'
import { localStorageDelete, localStorageGet } from 'utils/localStorage'

const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

/**
 * Hook to detect is current user authenticated or not
 * @returns {boolean} true if user is authenticated, false otherwise
 */
export function useIsAuthenticated() {
  const [state] = useAppStore()
  let result = state.user.isAuthenticated
  const decodedJwt = parseJwt(localStorageGet('token', false))

  if (decodedJwt && decodedJwt.exp * 1000 < Date.now()) {
    result = false
  }

  return result
}

/**
 * Returns event handler to Logout current user
 * @returns {function} calling this event logs out current user
 */
export function useEventLogout() {
  const [, dispatch] = useAppStore()
  const { enqueueSnackbar } = useSnackbar()

  return useCallback(() => {
    localStorageDelete()
    enqueueSnackbar('Déconnexion réussie !', {
      variant: 'success',
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
    })
    dispatch({ name: 'user', action: { type: 'LOG_OUT' } })
  }, [dispatch])
}
