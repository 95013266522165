import { FunctionComponent, MouseEventHandler } from 'react'
import List from '@mui/material/List'
import { useAppStore } from 'store/AppStore'
import { Divider } from '@mui/material'
import SideBarNavItem from './SideBarNavItem'
import { LinkToPage } from '../../utils/type'

interface Props {
  items: Array<LinkToPage>
  showIcons?: boolean
  onClick?: MouseEventHandler
}

/**
 * Renders list of Navigation Items inside SideBar
 * @component SideBarNavList
 * @param {array} items - list of objects to render as navigation items
 * @param {boolean} [showIcons] - icons in navigation items are visible when true
 * @param {function} [onAfterLinkClick] - optional callback called when some navigation item was clicked
 */
const SideBarNavList: FunctionComponent<Props> = ({
  items,
  showIcons,
  onClick,
  ...restOfProps
}) => {
  const [state] = useAppStore()

  return (
    <List component="nav" {...restOfProps}>
      {items.map(({ icon, path, title, subMenus }) => (
        <SideBarNavItem
          key={`${title}-${path}`}
          icon={showIcons ? icon : undefined}
          path={path}
          title={title}
          subMenus={subMenus}
          onClick={onClick}
        />
      ))}
      {state.user.isAdmin && (
        <>
          <Divider
            sx={{
              backgroundColor: '#FFFFFF',
            }}
          />
          {/*
            <SideBarNavItem
              icon="build"
              path={`${process.env.REACT_APP_ADMIN_URL}/tableau-de-bord`}
              title="Espace Administration"
            />
          */}
          <SideBarNavItem
            icon="groups"
            href={`${process.env.REACT_APP_STAFF_URL}/tableau-de-bord`}
            title="Espace Staff"
          />
          <SideBarNavItem
            icon="menubook"
            href={`${process.env.REACT_APP_BOOKER_URL}/tableau-de-bord`}
            title="Espace Booker"
          />
        </>
      )}
    </List>
  )
}

export default SideBarNavList
