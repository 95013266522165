/* eslint-disable no-nested-ternary */
import { FunctionComponent, useCallback, MouseEvent } from 'react'
import {
  Stack,
  Divider,
  Drawer,
  DrawerProps,
  styled,
  Theme,
  CSSObject,
  Avatar,
} from '@mui/material'

import { useEventLogout } from 'hooks/auth'

// import { useAppStore } from '../../store/AppStore'
import AppIconButton from 'components/AppIconButton/AppIconButton'
import { useNavigate } from 'react-router-dom'
import AppIcon from 'components/AppIcon/AppIcon'
import { useAppStore } from 'store/AppStore'
import { LinkToPage } from '../../utils/type'
import SideBarNavList from './SideBarNavList'
import { SIDEBAR_WIDTH } from '../config'

interface Props
  extends Pick<
    DrawerProps,
    'anchor' | 'className' | 'open' | 'variant' | 'onClose'
  > {
  items: Array<LinkToPage>
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: SIDEBAR_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
})

const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: SIDEBAR_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiPaper-root': { backgroundColor: theme.palette.primary.main },
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

/**
 * Renders SideBar with Menu and User details
 * Actually for Authenticated users only, rendered in "Private Layout"
 * @component SideBar
 * @param {string} anchor - 'left' or 'right'
 * @param {boolean} open - the Drawer is visible when true
 * @param {string} variant - variant of the Drawer, one of 'permanent', 'persistent', 'temporary'
 * @param {function} onClose - called when the Drawer is closing
 */
const SideBar: FunctionComponent<Props> = ({
  anchor,
  open,
  variant,
  items,
  onClose,
  ...restOfProps
}) => {
  const navigate = useNavigate()
  const [state] = useAppStore()
  const onLogout = useEventLogout()

  const handleLogout = async () => {
    onLogout()
    navigate('/')
  }
  const handleAfterLinkClick = useCallback(
    (event: MouseEvent) => {
      if (variant === 'permanent' && typeof onClose === 'function') {
        onClose(event, 'backdropClick')
      }
    },
    [variant, onClose],
  )

  return (
    <CustomDrawer
      anchor={anchor}
      open={open}
      variant={variant}
      onClose={onClose}>
      <DrawerHeader>
        <AppIconButton
          icon="chevronleft"
          color="#FFFFFF"
          onClick={handleAfterLinkClick}
        />
      </DrawerHeader>
      <Stack
        sx={{
          height: '100%',
          padding: 2,
          paddingTop: 0,
        }}
        {...restOfProps}>
        <Stack
          alignItems="center"
          minHeight="fit-content"
          marginBottom={2}
          {...restOfProps}>
          <Avatar
            sx={{
              width: 200,
              height: open ? 130 : 60,
              backgroundColor: 'inherit',
              '& .MuiSvgIcon-root': {
                width: open ? 150 : 60,
                height: open ? 150 : 60,
                paddingBottom: open ? 3.8 : 'initial',
              },
            }}
            alt="Les chouquettes blanches">
            <AppIcon />
          </Avatar>
        </Stack>
        <Divider
          sx={{
            backgroundColor: '#FFFFFF',
          }}
        />
        {(state.user.isAdmin || state.user.isActive) && (
          <SideBarNavList items={items} showIcons />
        )}
        <Divider
          sx={{
            backgroundColor: '#FFFFFF',
          }}
        />
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            marginTop: 2,
          }}>
          <AppIconButton
            icon="logout"
            title="Déconnexion"
            color="#FFFFFF"
            onClick={handleLogout}
          />
        </Stack>
      </Stack>
    </CustomDrawer>
  )
}

export default SideBar
