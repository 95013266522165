import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react'
import AppIconButton from 'components/AppIconButton/AppIconButton'
import { CircularProgress, Grid, Stack, Typography } from '@mui/material'
import { useAppStore } from 'store/AppStore'
import { LinkToPage } from '../utils/type'
import { useOnMobile } from '../hooks/layout'
import {
  SIDEBAR_DESKTOP_ANCHOR,
  SIDEBAR_WIDTH,
  TOPBAR_DESKTOP_HEIGHT,
  TOPBAR_MOBILE_HEIGHT,
} from './config'
import TopBar from './TopBar/TopBar'
import SideBar from './SideBar/SideBar'
import Footer from './Footer/Footer'

/**
 * SideBar navigation items with links
 */
const SIDEBAR_ITEMS: Array<LinkToPage> = [
  {
    title: 'Tableau de bord',
    path: '/tableau-de-bord',
    icon: 'dashboard',
  },
  {
    title: 'Planning',
    path: '/planning',
    icon: 'calendarmonth',
  },
  {
    title: 'Commandes',
    icon: 'receipt',
    path: '/commandes',
    subMenus: [
      {
        title: 'Nouvelle commande',
        path: '/nouvelle-commande',
        icon: 'add',
      },
      {
        title: 'Commandes à venir',
        path: '/commandes',
        icon: 'callMade',
      },
      {
        title: 'Commandes passées',
        path: '/historique-des-commandes',
        icon: 'callReceived',
      },
      {
        title: 'Toutes les commandes',
        path: '/toutes-les-commandes',
        icon: 'allInclusive',
      },
    ],
  },
  {
    title: 'Fiches émargements',
    path: '/liste-des-emargements',
    icon: 'tapas',
  },
  {
    title: 'Mon profil',
    path: '/mon-compte',
    icon: 'account',
  },
]

/**
 * Renders "Private Layout" composition
 * @component PrivateLayout
 */
const PrivateLayout: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [state] = useAppStore()
  const onMobile = useOnMobile()
  const [sideBarVisible, setSideBarVisible] = useState(true)
  const sidebarVariant = 'permanent'

  useEffect(() => {
    if (onMobile) setSideBarVisible(false)
  }, [onMobile])

  const onSideBarOpen = () => {
    if (!sideBarVisible) setSideBarVisible(true) // Don't re-render Layout when SideBar is already open
  }

  const onSideBarClose = () => {
    if (sideBarVisible) setSideBarVisible(false) // Don't re-render Layout when SideBar is already closed
  }

  return (
    <Stack
      direction="column"
      sx={{
        minHeight: '100vh', // Full screen height
        paddingTop: onMobile ? TOPBAR_MOBILE_HEIGHT : TOPBAR_DESKTOP_HEIGHT,
        paddingLeft:
          sideBarVisible && SIDEBAR_DESKTOP_ANCHOR.includes('left')
            ? SIDEBAR_WIDTH
            : 8,
        paddingRight:
          sideBarVisible && SIDEBAR_DESKTOP_ANCHOR.includes('right')
            ? SIDEBAR_WIDTH
            : 0,
      }}>
      <Stack component="header">
        <TopBar
          title="Les chouquettes blanches"
          open={sideBarVisible}
          startNode={
            <AppIconButton
              icon="menu"
              sx={{
                color: 'white',
                marginRight: 5,
                ...(sideBarVisible && { display: 'none' }),
              }}
              onClick={sideBarVisible ? onSideBarClose : onSideBarOpen}
            />
          }
        />

        <SideBar
          anchor={SIDEBAR_DESKTOP_ANCHOR}
          open={sideBarVisible}
          variant={sidebarVariant}
          items={SIDEBAR_ITEMS}
          onClose={onSideBarClose}
        />
      </Stack>

      <Stack
        component="main"
        sx={{
          flexGrow: 1, // Takes all possible space
          margin: '0 15px',
        }}>
        {state.isLoading ? (
          <CircularProgress sx={{ m: 'auto' }} color="secondary" />
        ) : (
          !state.user.isActive && (
            <Grid container item xs={12} spacing={3} mb={4}>
              <Typography component="h2" variant="h4">
                {state.user.profileComplete
                  ? 'Votre compte est en cours de validation.'
                  : 'Veuillez compléter votre profil pour accéder à la plateforme.'}
              </Typography>
            </Grid>
          )
        )}
        {children}
      </Stack>

      <Footer />
    </Stack>
  )
}

export default PrivateLayout
