import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { SnackbarProvider } from 'notistack'
import { AppStore } from 'store/AppStore'
import CustomRoutes from 'routes/CustomRoutes'
import Layout from 'layout/Layout'
import fr from 'date-fns/locale/fr'
import AppThemeProvider from 'theme/AppThemeProvider'

const queryClient = new QueryClient()

/**
 * Root Application Component
 */
const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
      <QueryClientProvider client={queryClient}>
        <AppStore>
          <AppThemeProvider>
            <BrowserRouter>
              <SnackbarProvider>
                <Layout>
                  <CustomRoutes />
                </Layout>
              </SnackbarProvider>
            </BrowserRouter>
          </AppThemeProvider>
        </AppStore>
      </QueryClientProvider>
    </LocalizationProvider>
  )
}

export default App
