import { Typography } from '@mui/material'
import AppLink from 'components/AppLink/AppLink'

const Copyright = (props: any) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}>
      {'Copyright © '}
      <AppLink color="inherit" href="#">
        Les chouquettes blanches
      </AppLink>{' '}
      {new Date().getFullYear()}.
    </Typography>
  )
}

export default Copyright
