import { ThemeOptions } from '@mui/material'
import PALETTE_COLORS from './colors'

/**
 * MUI theme options
 */
const DEFAULT_THEME: ThemeOptions = {
  palette: {
    ...PALETTE_COLORS,
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#048B9A',
        },
      },
    },
  },
}

export default DEFAULT_THEME
