import { FunctionComponent, PropsWithChildren } from 'react'
import { Stack, styled } from '@mui/material'
import { useOnMobile } from 'hooks/layout'
import { useIsAuthenticated } from '../hooks/auth'
import PrivateLayout from './PrivateLayout'
import PublicLayout from './PublicLayout'

const Root = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  minHeight: '100vh',
  background: theme.palette.grey[50],
  paddingTop: useOnMobile() ? 56 : 64,
  boxSizing: 'border-box',
}))

const Layout: FunctionComponent<PropsWithChildren> = (props) => {
  return (
    <Root>
      {useIsAuthenticated() ? (
        <PrivateLayout {...props} />
      ) : (
        <PublicLayout {...props} />
      )}
    </Root>
  )
}

export default Layout
