import { Stack } from '@mui/material'
import Copyright from 'components/Copyright/Copyright'

const Footer = () => {
  return (
    <Stack
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
      }}>
      <Copyright />
    </Stack>
  )
}

export default Footer
