import { AppStoreState } from 'types/store'

import { USER_REDUCER_NAME, userReducer } from './reducers/user'

/**
 * Reducer for global AppStore using "Redux styled" actions
 * @param {object} state - current/default state
 * @param {string} action.type - unique name of the action
 * @param {*} [action.payload] - optional data object or the function to get data object
 */
const AppReducer: React.Reducer<AppStoreState, any> = (
  { user, isLoading }: AppStoreState,
  reducer,
) => {
  switch (reducer.name) {
    case USER_REDUCER_NAME:
      return {
        isLoading,
        user: userReducer(user, reducer.action),
      }
      break
    case 'SET_IS_LOADING':
      return {
        isLoading: reducer.action.isLoading,
        user,
      }
      break
    default:
      return { isLoading, user }
  }
}

export default AppReducer
