import { UserStoreState } from 'types/user'

const INITIAL_USER_STATE: UserStoreState = {
  isAuthenticated: false,
  isActive: false,
  isAdmin: false,
  current: undefined,
  profileComplete: false,
}

export default INITIAL_USER_STATE
