import createCache from '@emotion/cache'
import { CacheProvider, EmotionCache } from '@emotion/react'
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material'
import { FunctionComponent, useMemo, PropsWithChildren } from 'react'
import DEFAULT_THEME from './theme'

// Setting .prepend: true moves MUI styles to the top of the <head> so they're loaded first.
// It allows developers to easily override MUI styles with other styling solutions, like CSS modules.
function createEmotionCache() {
  return createCache({ key: 'css', prepend: true })
}

// Client-side cache, shared for the whole session of the user in the browser.
const CLIENT_SIDE_EMOTION_CACHE = createEmotionCache()

interface Props extends PropsWithChildren {
  emotionCache?: EmotionCache
}

/**
 * Renders composition of Emotion's CacheProvider + MUI's ThemeProvider to wrap content of entire App
 * The Light or Dark themes applied depending on global .darkMode state
 * @param {EmotionCache} [emotionCache] - shared Emotion's cache to use in the App
 */
const AppThemeProvider: FunctionComponent<Props> = ({
  children,
  emotionCache = CLIENT_SIDE_EMOTION_CACHE,
}) => {
  const theme = useMemo(() => createTheme(DEFAULT_THEME), [])

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline /* MUI Styles */ />
        {children}
      </ThemeProvider>
    </CacheProvider>
  )
}

export default AppThemeProvider
