import { FunctionComponent, MouseEventHandler, useState } from 'react'
import {
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material'

import { useLocation } from 'react-router-dom'

import AppIcon from 'components/AppIcon/AppIcon'
import AppLink from 'components/AppLink/AppLink'
import AppIconButton from 'components/AppIconButton/AppIconButton'
import { LinkToPage } from '../../utils/type'

interface Props extends LinkToPage {
  openInNewTab?: boolean
  selected?: boolean

  onClick?: MouseEventHandler
}

/**
 * Renders Navigation Item for SideBar, detects current url and sets selected state if needed
 * @component SideBarNavItem
 */
const SideBarNavItem: FunctionComponent<Props> = ({
  openInNewTab,
  icon,
  path,
  href,
  selected: propSelected = false,
  subtitle,
  title,
  subMenus,
  onClick,
}) => {
  const theme = useTheme()
  const location = useLocation()

  const subMenuSelected = (subPath: string) =>
    location.pathname === subPath || false

  const subPathSelected = () =>
    subMenus && subMenus.some((item: any) => subMenuSelected(item.path))

  const selected =
    propSelected ||
    (path && path.length > 1 && location.pathname.startsWith(path)) ||
    subPathSelected() ||
    false

  const [expanded, setExpanded] = useState(true)
  const handleExpandedSubMenu = () => {
    setExpanded(!expanded)
  }

  return (
    <>
      <ListItemButton
        component={AppLink}
        selected={selected}
        to={path}
        href={href || ''} // Hard reset for .href property, otherwise links are always opened in new tab :(
        openInNewTab={openInNewTab}
        sx={{
          margin: '2px 0',
          borderRadius: 3,
          paddingLeft: 'unset',
          paddingRight: 'unset',
          backgroundColor: selected
            ? `${theme.palette.secondary.main} !important`
            : `${theme.palette.primary.main} !important`,
          ':hover': {
            backgroundColor: !selected
              ? `${theme.palette.secondary.main} !important`
              : `${theme.palette.primary.main} !important`,
          },
        }}
        onClick={onClick}>
        <ListItemIcon
          sx={{
            paddingLeft: 0.6,
            color: '#FFFFFF',
          }}>
          {icon && <AppIcon icon={icon} />}
        </ListItemIcon>
        <ListItemText
          primary={title}
          secondary={subtitle}
          sx={{
            color: '#FFFFFF',
          }}
        />
        {subMenus !== undefined &&
          (!expanded ? (
            <AppIconButton
              color="#FFFFFF"
              icon="expandless"
              onClick={(e) => {
                e.preventDefault()
                handleExpandedSubMenu()
              }}
            />
          ) : (
            <AppIconButton
              color="#FFFFFF"
              icon="expandmore"
              onClick={(e) => {
                e.preventDefault()
                handleExpandedSubMenu()
              }}
            />
          ))}
      </ListItemButton>
      {subMenus !== undefined &&
        subMenus.map((item: any) => (
          <Collapse in={expanded} timeout="auto" key={`${title}-${item.path}`}>
            <ListItemButton
              component={AppLink}
              selected={subMenuSelected(item.path)}
              to={item.path}
              href="" // Hard reset for .href property, otherwise links are always opened in new tab :(
              openInNewTab={item.openInNewTab}
              sx={{
                margin: '2px 0',
                paddingLeft: 2,
                borderRadius: 3,
                paddingRight: 'unset',
                backgroundColor: subMenuSelected(item.path)
                  ? `${theme.palette.secondary.main} !important`
                  : `${theme.palette.primary.main} !important`,
                ':hover': {
                  backgroundColor: !subMenuSelected(item.path)
                    ? `${theme.palette.secondary.main} !important`
                    : `${theme.palette.primary.main} !important`,
                },
              }}
              onClick={onClick}>
              <ListItemIcon
                sx={{
                  color: '#FFFFFF',
                }}>
                {icon && <AppIcon icon={item.icon} />}
              </ListItemIcon>
              <ListItemText
                primary={item.title}
                secondary={item.subtitle}
                primaryTypographyProps={{
                  fontSize: '0.8rem',
                  color: '#FFFFFF',
                }}
              />
            </ListItemButton>
          </Collapse>
        ))}
    </>
  )
}

export default SideBarNavItem
