import { useEffect } from 'react'
import { useAppStore } from 'store/AppStore'
import AuthApi from 'api/auth'
import { useQuery } from 'react-query'
import { localStorageGet } from 'utils/localStorage'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { isProfileComplete } from 'utils/utils'
import PublicRoutes from './PublicRoutes'
import PrivateRoutes from './PrivateRoutes'
import { useEventLogout, useIsAuthenticated } from '../hooks/auth'

/**
 * Renders routes depending on Authenticated or Anonymous users
 */
const CustomRoutes = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [state, dispatch] = useAppStore()
  const isAuthenticated = useIsAuthenticated()
  const onLogout = useEventLogout()

  const { isLoading, refetch: verifyToken } = useQuery<any, Error>(
    'verify-token',
    async () => AuthApi.verifyToken(),
    {
      enabled: false,
      retry: false,
      onSuccess: (response) => {
        if (
          Object.keys(response).length !== 0 &&
          (!response.roles.includes('administrator') ||
            !response.roles.includes('caterer'))
        ) {
          if (response.roles.includes('staff')) {
            window.document.location = process.env.REACT_APP_STAFF_URL as string
          } else if (response.roles.includes('booker')) {
            window.document.location = process.env
              .REACT_APP_BOOKER_URL as string
          }
        }

        if (response && !isAuthenticated) {
          dispatch({
            name: 'user',
            action: {
              type: 'LOG_IN',
              payload: {
                current: response,
                profileComplete: isProfileComplete(response),
                isActive: response.isActive,
                isAdmin: response.roles.includes('administrator'),
                hasCook: response.caterer.bookers.includes(
                  process.env.REACT_APP_DEFAULT_COOK_ID as string,
                ),
              },
            },
          })
          if (!response.isActive) {
            navigate('/mon-compte')
          }
        }
        dispatch({ name: 'SET_IS_LOADING', action: { isLoading: false } })
      },
      onError: (err: any) => {
        console.log(err)
        onLogout()
        dispatch({ name: 'SET_IS_LOADING', action: { isLoading: false } })
        enqueueSnackbar('Veuillez vous reconnecter !', {
          variant: 'warning',
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        })
        navigate('/connexion')
      },
    },
  )

  useEffect(() => {
    if (localStorageGet('token', false)) {
      verifyToken()
    }
  }, [state.isLoading, isLoading])

  return isAuthenticated ? <PrivateRoutes /> : <PublicRoutes />
}

export default CustomRoutes
