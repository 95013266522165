import { PaletteOptions, SimplePaletteColorOptions } from '@mui/material'

const COLOR_PRIMARY: SimplePaletteColorOptions = {
  main: '#C79b60',
  contrastText: '#FFFFFF',
}

const COLOR_SECONDARY: SimplePaletteColorOptions = {
  main: '#048B9A',
  contrastText: '#FFFFFF',
}

const PALETTE_COLORS: Partial<PaletteOptions> = {
  primary: COLOR_PRIMARY,
  secondary: COLOR_SECONDARY,
  // error: COLOR_ERROR,
  // warning: COLOR_WARNING;
  // info: COLOR_INFO;
  // success: COLOR_SUCCESS;
}

/**
 * MUI colors set to use in theme.palette
 */
export default PALETTE_COLORS
